import p1_img from "./product_1.png";
import p2_img from "./product_2.png";
import p3_img from "./product_3.png";
import p4_img from "./product_4.png";
import p5_img from "./product_5.png";
import p6_img from "./product_6.png";
import p7_img from "./product_7.png";
import p8_img from "./product_8.png";
import p9_img from "./product_9.png";
import p10_img from "./product_10.png";
import p11_img from "./product_11.png";
import p12_img from "./product_12.png";
import p13_img from "./product_13.png";
import p14_img from "./product_14.png";
import p15_img from "./product_15.png";
import p16_img from "./product_16.png";
import p17_img from "./product_17.png";
import p18_img from "./product_18.png";
import p19_img from "./product_19.png";
import p20_img from "./product_20.png";
import p21_img from "./product_21.png";
import p22_img from "./product_22.png";
import p23_img from "./product_23.png";
import p24_img from "./product_24.png";
import p25_img from "./product_25.png";
import p26_img from "./product_26.png";
import p27_img from "./product_27.png";
import p28_img from "./product_28.png";
import p29_img from "./product_29.png";
import p30_img from "./product_30.png";
import p31_img from "./product_31.png";
import p32_img from "./product_32.png";
import p33_img from "./product_33.png";
import p34_img from "./product_34.png";
import p35_img from "./product_35.png";
import p36_img from "./product_36.png";

let all_product = [
  {
    id: 1,
    name: "Tenet",
    year: "2020",
    category: "",
    genre: "Action, Sci-Fi, Thriller",
    image: p1_img,
    streaming_price: 4.99,
    streaming_old_price: 9.99,
    physical_price: 14.99,
    physical_old_price: 19.99,
    price: "",
    movie_description: "Armed with only one word, Tenet, and fighting for the survival of the entire world, a Protagonist journeys through a twilight world of international espionage on a mission that will unfold in something beyond real time.",
    movie_stars: "John David Washington, Robert Pattinson, Elizabeth Debicki",
    movie_director: "Christopher Nolan",
    movie_runtime: "2h 30m",
    movie_rating: "7.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=LdOM0x0XDMo",
  },
  {
    id: 2,
    name: "Wonder Woman 1984",
    year: "2020",
    category: "",
    genre: "Action, Adventure, Fantasy",
    image: p2_img,
    streaming_price: 3.99,
    streaming_old_price: 8.99,
    physical_price: 13.99,
    physical_old_price: 18.99,
    price: "",
    movie_description: "Diana must contend with a work colleague and businessman, whose desire for extreme wealth sends the world down a path of destruction, after an ancient artifact that grants wishes goes missing.",
    movie_stars: "Gal Gadot, Chris Pine, Kristen Wiig",
    movie_director: "Patty Jenkins",
    movie_runtime: "2h 31m",
    movie_rating: "5.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=sfM7_JLk-84",
  },
  {
    id: 3,
    name: "Soul",
    year: "2020",
    category: "",
    genre: "Animation, Adventure, Comedy",  
    image: p3_img,
    streaming_price: 3.49,
    streaming_old_price: 8.49,
    physical_price: 13.49,
    physical_old_price: 18.49,
    price: "",
    movie_description: "After landing the gig of a lifetime, a New York jazz pianist suddenly finds himself trapped in a strange land between Earth and the afterlife.",
    movie_stars: "Jamie Foxx, Tina Fey, Graham Norton",
    movie_director: "Pete Docter, Kemp Powers",
    movie_runtime: "1h 40m",
    movie_rating: "8.1",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=xOsLIiBStEs",
  },
  {
    id: 4,
    name: "Nomadland",
    year: "2020",
    category: "",
    genre: "Drama",
    image: p4_img,
    streaming_price: 4.99,    
    streaming_old_price: 9.99,
    physical_price: 14.99,
    physical_old_price: 19.99,
    price: "",
    movie_description: "A woman in her sixties, after losing everything in the Great Recession, embarks on a journey through the American West, living as a van-dwelling modern-day nomad.",
    movie_stars: "Frances McDormand, David Strathairn, Linda May",
    movie_director: "Chloé Zhao",
    movie_runtime: "1h 47m",
    movie_rating: "7.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=6sxCFZ8_d84",
  },
  {
    id: 5,
    name: "Birds of Prey",
    year: "2020",
    category: "",
    genre: "Action, Adventure, Crime",
    image: p5_img,
    streaming_price: 5.49,
    streaming_old_price: 10.49,
    physical_price: 15.49,
    physical_old_price: 20.49,
    price: "",
    movie_description: "After splitting with the Joker, Harley Quinn joins superheroines Black Canary, Huntress, and Renee Montoya to save a young girl from an evil crime lord.",
    movie_stars: "Margot Robbie, Rosie Perez, Mary Elizabeth Winstead",
    movie_director: "Cathy Yan",
    movie_runtime: "1h 49m",
    movie_rating: "6.1",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=kGM4uYZzfu0",
  },
  {
    id: 6,
    name: "The Invisible Man",
    year: "2020",
    category: "",
    genre: "Horror, Mystery, Sci-Fi",
    image: p6_img,
    streaming_price: 5.49,
    streaming_old_price: 10.49,
    physical_price: 15.49,
    physical_old_price: 20.49,
    price: "",
    movie_description: "When Cecilia's abusive ex takes his own life and leaves her his fortune, she suspects his death was a hoax. As a series of coincidences turn lethal, Cecilia works to prove that she is being hunted by someone nobody can see.",
    movie_stars: "Elisabeth Moss, Oliver Jackson-Cohen, Harriet Dyer",
    movie_director: "Leigh Whannell",
    movie_runtime: "2h 4m",
    movie_rating: "7.1",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=dSBsNeYqh-k",
  },
  {
    id: 7,
    name: "Enola Holmes",
    year: "2020",
    category: "",
    genre: "Adventure, Crime, Drama",
    image: p7_img,
    streaming_price: 3.49,
    streaming_old_price: 8.49,
    physical_price: 13.49,
    physical_old_price: 18.49,
    price: "",
    movie_description: "When Enola Holmes-Sherlock's teen sister-discovers her mother missing, she sets off to find her, becoming a super-sleuth in her own right as she outwits her famous brother and unravels a dangerous conspiracy around a mysterious young Lord.",
    movie_stars: "Millie Bobby Brown, Henry Cavill, Sam Claflin",
    movie_director: "Harry Bradbeer",
    movie_runtime: "2h 3m",
    movie_rating: "6.6",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
  {
    id: 8,
    name: "The Trial Of The Chicago 7",
    year: "2020",
    category: "",
    genre: "Drama, History, Thriller",
    image: p8_img,
    streaming_price: 4.49,
    streaming_old_price: 9.49,
    physical_price: 14.49,
    physical_old_price: 19.49,
    price: "",
    movie_description: "The story of 7 people on trial stemming from various charges surrounding the uprising at the 1968 Democratic National Convention in Chicago, Illinois.",
    movie_stars: "Eddie Redmayne, Alex Sharp, Sacha Baron Cohen",
    movie_director: "Aaron Sorkin",
    movie_runtime: "2h 9m",
    movie_rating: "7.8",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=FVb6EdKDBfU",
  },
  {
    id: 9,
    name: "Da 5 Bloods",
    year: "2020",
    category: "",
    genre: "Drama, War",
    image: p9_img,
    streaming_price: 2.99,
    streaming_old_price: 7.99,
    physical_price: 12.99,
    physical_old_price: 17.99,
    price: "",
    movie_description: "Four African-American vets battle the forces of man and nature when they return to Vietnam seeking the remains of their fallen squad leader and the gold fortune he helped them hide.",
    movie_stars: "Delroy Lindo, Jonathan Majors, Clarke Peters",
    movie_director: "Spike Lee",
    movie_runtime: "2h 34m",
    movie_rating: "6.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=D5RDTPfsLAI",
  },
  {
    id: 10,
    name: "News Of The World",
    year: "2020",
    category: "",
    genre: "Action, Adventure, Drama",
    image: p10_img,
    streaming_price: 3.49,
    streaming_old_price: 8.49,
    physical_price: 13.49,
    physical_old_price: 18.49,
    price: "",
    movie_description: "A Civil War veteran agrees to deliver a girl taken by the Kiowa people years ago to her aunt and uncle against her will. They travel hundreds of miles and face grave dangers as they search for a place that either can call home.",
    movie_stars: "Tom Hanks, Helena Zengel, Tom Astor",
    movie_director: "Paul Greengrass",
    movie_runtime: "1h 58m",
    movie_rating: "6.8",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=0m5m3Yj5Ywk",
  },
  {
    id: 11,
    name: "Mank",
    year: "2020",
    category: "",
    genre: "Biography, Comedy, Drama",
    image: p11_img,
    streaming_price: 5.49,
    streaming_old_price: 10.49,
    physical_price: 15.49,
    physical_old_price: 20.49,
    price: "",
    movie_description: "1930s Hollywood is re-evaluated through the eyes of scathing social critic and alcoholic screenwriter Herman J. Mankiewicz as he races to finish the screenplay of O Mundo a Seus Pés (1941).",
    movie_stars: "Gary Oldman, Amanda Seyfried, Lily Collins",
    movie_director: "David Fincher",
    movie_runtime: "2h 11m",
    movie_rating: "7.0",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=5P9V5mG8H7Y",
  },
  {
    id: 12,
    name: "Sound Of Metal",
    year: "2020",
    category: "",
    genre: "Drama, Music",
    image: p12_img,
    streaming_price: 5.99,
    streaming_old_price: 10.99,
    physical_price: 15.99,
    physical_old_price: 20.99,
    price: "",
    movie_description: "A heavy-metal drummer's life is thrown into freefall when he begins to lose his hearing.",
    movie_stars: "Riz Ahmed, Olivia Cooke, Paul Raci",
    movie_director: "Darius Marder",
    movie_runtime: "2h 10m",
    movie_rating: "7.8",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=VFOrGkAvjAE",
  },
  {
    id: 13,
    name: "Judas And The Black Messiah",
    year: "2021",
    category: "",
    genre: "Biography, Drama, History",
    image: p13_img,
    streaming_price: 3.99,
    streaming_old_price: 8.99,
    physical_price: 13.99,
    physical_old_price: 18.99,
    price: "",
    movie_description: "Offered a plea deal by the FBI, William O'Neal infiltrates the Illinois chapter of the Black Panther Party to gather intelligence on Chairman Fred Hampton.",
    movie_stars: "Daniel Kaluuya, LaKeith Stanfield, Jesse Plemons",
    movie_director: "Shaka King",
    movie_runtime: "2h 6m",
    movie_rating: "7.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=sSjtGqRXQ9Y",
  },
  {
    id: 14,
    name: "Godzilla vs. Kong",
    year: "2021",
    category: "",
    genre: "Action, Sci-Fi, Thriller",
    image: p14_img,
    streaming_price: 5.49,
    streaming_old_price: 10.49,
    physical_price: 15.49,
    physical_old_price: 20.49,
    price: "",
    movie_description: "The epic next chapter in the cinematic Monsterverse pits two of the greatest icons in motion picture history against one another - the fearsome Godzilla and the mighty Kong - with humanity caught in the balance.",
    movie_stars: "Alexander Skarsgård, Millie Bobby Brown, Rebecca Hall",
    movie_director: "Adam Wingard",
    movie_runtime: "1h 53m",
    movie_rating: "6.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=odM92ap8_c0",
  },
  {
    id: 15,
    name: "Dune",
    year: "2021",
    category: "",
    genre: "Adventure, Drama, Sci-Fi",
    image: p15_img,
    streaming_price: 6.99,
    streaming_old_price: 11.99,
    physical_price: 16.99,
    physical_old_price: 21.99,
    price: "",
    movie_description: "Feature adaptation of Frank Herbert's science fiction novel, about the son of a noble family entrusted with the protection of the most valuable asset and most vital element in the galaxy.",
    movie_stars: "Timothée Chalamet, Rebecca Ferguson, Zendaya",
    movie_director: "Denis Villeneuve",
    movie_runtime: "2h 35m",
    movie_rating: "8.3",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=n9xhJrPXop4",
  },
  {
    id: 16,
    name: "Cruella",
    year: "2021",
    category: "",
    genre: "Comedy, Crime",
    image: p16_img,
    streaming_price: 4.49,
    streaming_old_price: 9.49,
    physical_price: 14.49,
    physical_old_price: 19.49,
    price: "",
    movie_description: "A live-action prequel feature film following a young Cruella de Vil.",
    movie_stars: "Emma Stone, Emma Thompson, Joel Fry",
    movie_director: "Craig Gillespie",
    movie_runtime: "2h 14m",
    movie_rating: "7.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=jpZrVxv1L7g",
  },
  {
    id: 17,
    name: "A Quiet Place - Part II",
    year: "2021",
    category: "",
    genre: "Drama, Horror, Sci-Fi",
    image: p17_img,
    streaming_price: 3.99,
    streaming_old_price: 8.99,
    physical_price: 13.99,
    physical_old_price: 18.99,
    price: "",
    movie_description: "Following the events at home, the Abbott family now face the terrors of the outside world. Forced to venture into the unknown, they realize the creatures that hunt by sound are not the only threats lurking beyond the sand path.",
    movie_stars: "Emily Blunt, Millicent Simmonds, Cillian Murphy",
    movie_director: "John Krasinski",
    movie_runtime: "1h 37m",
    movie_rating: "7.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=BpdDN9d9Jio",
  },
  {
    id: 18,
    name: "Suicide Squad",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Fantasy",
    image: p18_img,
    streaming_price: 5.49,
    streaming_old_price: 10.49,
    physical_price: 15.49,
    physical_old_price: 20.49,
    price: "",
    movie_description: "Supervillains Harley Quinn, Bloodsport, Peacemaker, and a collection of nutty cons at Belle Reve prison join the super-secret, super-shady Task Force X as they are dropped off at the remote, enemy-infused island of Corto Maltese.",
    movie_stars: "Margot Robbie, Idris Elba, John Cena",
    movie_director: "James Gunn",
    movie_runtime: "2h 12m",
    movie_rating: "7.2",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=WI3hecGO_04"
  },
  {
    id: 19,
    name: "Shang-Chi And The Legend Of The Ten Rings",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Fantasy",
    image: p19_img,
    streaming_price: 2.99,
    streaming_old_price: 7.99,
    physical_price: 12.99,
    physical_old_price: 17.99,
    price: "",
    movie_description: "Shang-Chi, the master of weaponry-based Kung Fu, is forced to confront his past after being drawn into the Ten Rings organization.",
    movie_stars: "Dave Callaham, Destin Daniel Cretton,, Andrew Lanham",
    movie_director: "Destin Daniel Cretton",
    movie_runtime: "2h 12m",
    movie_rating: "7.6",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=8YjFbMbfXaQ",
  },
  {
    id: 20,
    name: "Black Widow",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Sci-Fi",
    image: p20_img,
    streaming_price: 3.49,
    streaming_old_price: 8.49,
    physical_price: 13.49,
    physical_old_price: 18.49,
    price: "",
    movie_description: "Natasha Romanoff confronts the darker parts of her ledger when a dangerous conspiracy with ties to her past arises.",
    movie_stars: "Eric Pearson, Jac Schaeffer, Ned Benson",
    movie_director: "Cate Shortland",
    movie_runtime: "2h 14m",
    movie_rating: "6.7",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=Fp9pNPdNwjI",
  },
  {
    id: 21,
    name: "No Time To Die",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Thriller",
    image: p21_img,
    streaming_price: 7.99,
    streaming_old_price: 12.99,
    physical_price: 17.99,
    physical_old_price: 22.99,
    price: "",
    movie_description: "James Bond has left active service. His peace is short-lived when Felix Leiter, an old friend from the CIA, turns up asking for help, leading Bond onto the trail of a mysterious villain armed with dangerous new technology.",
    movie_stars: "Daniel Craig, Ana de Armas, Rami Malek",
    movie_director: "Cary Joji Fukunaga",
    movie_runtime: "2h 43m",
    movie_rating: "7.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=BIhNsAtPbPI",
  },
  {
    id: 22,
    name: "Spider-Man: No Way Home",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Fantasy",
    image: p22_img,
    streaming_price: 6.49,
    streaming_old_price: 11.49,
    physical_price: 16.49,
    physical_old_price: 21.49,
    price: "",
    movie_description: "With Spider-Man's identity now revealed, Peter asks Doctor Strange for help. When a spell goes wrong, dangerous foes from other worlds start to appear, forcing Peter to discover what it truly means to be Spider-Man.",
    movie_stars: "Tom Holland, Zendaya, Benedict Cumberbatch",
    movie_director: "Jon Watts",
    movie_runtime: "2h 28m",
    movie_rating: "8.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=rt-2cxAiPJk",
  },
  {
    id: 23,
    name: "The Matrix Resurrections",
    year: "2021",
    category: "",
    genre: "Action, Sci-Fi",
    image: p23_img,
    streaming_price: 5.99,
    streaming_old_price: 10.99,
    physical_price: 15.99,
    physical_old_price: 20.99,
    price: "",
    movie_description: "Return to a world of two realities: one, everyday life; the other, what lies behind it. To find out if his reality is a physical or mental construct, to truly know himself, Mr. Anderson will have to choose to follow the white rabbit once more.",
    movie_stars: "Keanu Reeves, Carrie-Anne Moss, Yahya Abdul-Mateen II",
    movie_director: "Lana Wachowski",
    movie_runtime: "2h 28m",
    movie_rating: "5.7",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=9ix7TUGVYIo",
  },
  {
    id: 24,
    name: "Encanto",
    year: "2021",
    category: "",
    genre: "Animation, Adventure, Comedy",
    image: p24_img,
    streaming_price: 1.99,
    streaming_old_price: 6.99,
    physical_price: 11.99,
    physical_old_price: 16.99,
    price: "",
    movie_description: "A Colombian teenage girl has to face the frustration of being the only member of her family without magical powers.",
    movie_stars: "Stephanie Beatriz, María Cecilia Botero, John Leguizamo",
    movie_director: "Jared Bush, Byron Howard",
    movie_runtime: "1h 42m",
    movie_rating: "7.7",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=V5z3cr8AB5g",
  },
  {
    id: 25,
    name: "Raya And The Last Dragon",
    year: "2021",
    category: "",
    genre: "Animation, Action, Adventure",
    image: p25_img,
    streaming_price: 1.49,
    streaming_old_price: 6.49,
    physical_price: 11.49,
    physical_old_price: 16.49,
    price: "",
    movie_description: "In a realm known as Kumandra, a re-imagined Earth inhabited by an ancient civilization, a warrior named Raya is determined to find the last dragon.",
    movie_stars: "Kelly Marie Tran, Awkwafina, Gemma Chan",
    movie_director: "Don Hall, Carlos López Estrada",
    movie_runtime: "1h 47m",
    movie_rating: "7.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=1VIZ89FEjYI",
  },
  {
    id: 26,
    name: "Army Of The Dead",
    year: "2021",
    category: "",
    genre: "Action, Crime, Horror",
    image: p26_img,
    streaming_price: 2.99,
    streaming_old_price: 7.99,
    physical_price: 12.99,
    physical_old_price: 17.99,
    price: "",
    movie_description: "Following a zombie outbreak in Las Vegas, a group of mercenaries take the ultimate gamble, venturing into the quarantine zone to pull off the greatest heist ever attempted.",
    movie_stars: "Dave Bautista, Ella Purnell, Ana de la Reguera",
    movie_director: "Zack Snyder",
    movie_runtime: "2h 28m",
    movie_rating: "5.8",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=tI1JGPhYBS8",
  },
  {
    id: 27,
    name: "Luca",
    year: "2021",
    category: "",
    genre: "Animation, Adventure, Comedy",
    image: p27_img,
    streaming_price: 4.49,
    streaming_old_price: 9.49,
    physical_price: 14.49,
    physical_old_price: 19.49,
    price: "",
    movie_description: "On the Italian Riviera, an unlikely but strong friendship grows between a human being and a sea monster disguised as a human.",
    movie_stars: "Jacob Tremblay, Jack Dylan Grazer, Emma Berman",
    movie_director: "Enrico Casarosa",
    movie_runtime: "1h 35m",
    movie_rating: "7.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=mYfJxlgR2jw",
  },
  {
    id: 28,
    name: "Space Jam: A New Legacy",
    year: "2021",
    category: "",
    genre: "Animation, Adventure, Comedy",
    image: p28_img,
    streaming_price: 2.99,
    streaming_old_price: 7.99,
    physical_price: 12.99,
    physical_old_price: 17.99,
    price: "",
    movie_description: "A rogue artificial intelligence kidnaps the son of famed basketball player LeBron James, who then has to work with Bugs Bunny to win a basketball game.",
    movie_stars: "LeBron James, Don Cheadle, Cedric Joe",
    movie_director: "Malcolm D. Lee",
    movie_runtime: "1h 55m",
    movie_rating: "4.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=olXYZOsXw_o",
  },
  {
    id: 29,
    name: "The Green Knight",
    year: "2021",
    category: "",
    genre: "Adventure, Drama, Fantasy",
    image: p29_img,
    streaming_price: 3.99,
    streaming_old_price: 8.99,
    physical_price: 13.99,
    physical_old_price: 18.99,
    price: "",
    movie_description: "A fantasy re-telling of the medieval story of Sir Gawain and the Green Knight.",
    movie_stars: "Dev Patel, Alicia Vikander, Joel Edgerton",
    movie_director: "David Lowery",
    movie_runtime: "2h 10m",
    movie_rating: "6.7",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=VoJc2tH3WBw",
  },
  {
    id: 30,
    name: "Eternals",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Drama",
    image: p30_img,
    streaming_price: 2.49,
    streaming_old_price: 7.49,
    physical_price: 12.49,
    physical_old_price: 17.49,
    price: "",
    movie_description: "The saga of the Eternals, a race of immortal beings who lived on Earth and shaped its history and civilizations.",
    movie_stars: "Chloé Zhao, Kaz Firpo, Ryan Firpo",
    movie_director: "Chloé Zhao",
    movie_runtime: "2h 37m",
    movie_rating: "6.7",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=0WVDKZJkGlY",
  },
  {
    id: 31,
    name: "Halloween Kills",
    year: "2021",
    category: "",
    genre: "Horror, Thriller",
    image: p31_img,
    streaming_price: 2.49,
    streaming_old_price: 7.49,
    physical_price: 12.49,
    physical_old_price: 17.49,
    price: "",
    movie_description: "The saga of Michael Myers and Laurie Strode continues in the next thrilling chapter of the Halloween series.",
    movie_stars: "Jamie Lee Curtis, Judy Greer, Andi Matichak",
    movie_director: "David Gordon Green",
    movie_runtime: "1h 45m",
    movie_rating: "6.1",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=VqJZb4d7j4E",
  },
  {
    id: 32,
    name: "Free Guy",
    year: "2021",
    category: "",
    genre: "Action, Adventure, Comedy",
    image: p32_img,
    streaming_price: 1.99,
    streaming_old_price: 6.99,
    physical_price: 11.99,
    physical_old_price: 16.99,
    price: "",
    movie_description: "A bank teller discovers that he's actually an NPC inside a brutal, open world video game.",
    movie_stars: "Ryan Reynolds, Jodie Comer, Taika Waititi",
    movie_director: "Shawn Levy",
    movie_runtime: "1h 55m",
    movie_rating: "7.5",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=X2m-08cOAbc",
  },
  {
    id: 33,
    name: "The Last Duel",
    year: "2021",
    category: "",
    genre: "Action, Drama, History",
    image: p33_img,
    streaming_price: 3.49,
    streaming_old_price: 8.49,
    physical_price: 13.49,
    physical_old_price: 18.49,
    price: "",
    movie_description: "King Charles VI declares that Knight Jean de Carrouges settle his dispute with his squire by challenging him to a duel.",
    movie_stars: "Matt Damon, Adam Driver, Jodie Comer",
    movie_director: "Ridley Scott",
    movie_runtime: "2h 32m",
    movie_rating: "7.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=Q2WueoM1Jzg",
  },
  {
    id: 34,
    name: "Halloween Ends",
    year: "2022",
    category: "",
    genre: "Horror, Thriller",
    image: p34_img,
    streaming_price: 2.49,
    streaming_old_price: 7.49,
    physical_price: 12.49,
    physical_old_price: 17.49,
    price: "",
    movie_description: "The saga of Michael Myers and Laurie Strode comes to a thrilling conclusion in the thirteenth film of the Halloween series.",
    movie_stars: "Jamie Lee Curtis, Judy Greer, Andi Matichak",
    movie_director: "David Gordon Green",
    movie_runtime: "1h 45m",
    movie_rating: "6.1",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=VqJZb4d7j4E",
  },
  {
    id: 35,
    name: "Morbius",
    year: "2022",
    category: "",
    genre: "Action, Adventure, Fantasy",
    image: p35_img,
    streaming_price: 1.99,
    streaming_old_price: 6.99,
    physical_price: 11.99,
    physical_old_price: 16.99,
    price: "",
    movie_description: "Biochemist Michael Morbius tries to cure himself of a rare blood disease, but he inadvertently infects himself with a form of vampirism instead.",
    movie_stars: "Jared Leto, Matt Smith, Adria Arjona",
    movie_director: "Daniel Espinosa",
    movie_runtime: "1h 48m",
    movie_rating: "5.4",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=9Z9j8kQ7J5s",
  },
  {
    id: 36,
    name: "Thor: Love and Thunder",
    year: "2022",
    category: "",
    genre: "Action, Adventure, Fantasy",
    image: p36_img,
    streaming_price: 6.99,
    streaming_old_price: 11.99,
    physical_price: 16.99,
    physical_old_price: 21.99,
    price: "",
    movie_description: "The sequel to Thor: Ragnarok and the fourth movie in the Thor saga.",
    movie_stars: "Chris Hemsworth, Natalie Portman, Christian Bale",
    movie_director: "Taika Waititi",
    movie_runtime: "2h 11m",
    movie_rating: "7.6",
    review_count: Math.floor(Math.random() * 800) + 100,
    movie_trailer: "https://www.youtube.com/watch?v=Jz4M6QdJ5KI",
  },
];

export default all_product;